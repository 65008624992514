import { gql } from '@apollo/client';
import ProjectExpenseFragment from '../ProjectExpense/ProjectExpenseFragment';
import ProjectInvoiceRoleFragment from '../ProjectRole/ProjectInvoiceRoleFragment';
import InvoiceRoleExtraDataFragment from './InvoiceRoleExtraDataFragment';
import ProjectMilestoneFragment from '../ProjectMilestone/ProjectMilestoneFragment';

const InvoiceRecordFragment = gql`
  fragment InvoiceRecordData on InvoiceRecord {
  	service
    quantity
    amount
    rate
    description
    expense {
      ...ProjectExpenseData
    }
    role {
      ...ProjectInvoiceRoleData
    }
    roleExtraData {
      ...InvoiceRoleExtraDataData
    }
    milestone {
      ...ProjectMilestoneData
    }
  }
  ${ProjectExpenseFragment}
  ${ProjectInvoiceRoleFragment}
  ${InvoiceRoleExtraDataFragment}
  ${ProjectMilestoneFragment}
`;

export default InvoiceRecordFragment;
